import toast from "react-hot-toast";

var token = "";
const api_token = sessionStorage.getItem("api_token");
export function getToken() {
  return async (dispatch) => {
    const response = await fetch("/api/getTokens");
    const data = await response.json();
    const res_data = data.message;
   
    token = data.message;
    var return_response = {
      type: "CRC_TOKEN",
      payload: res_data,
    };
    dispatch(return_response);
  };
}
export function UserLogin(formData, setProccessing) {
  setProccessing(true);
  return async (dispatch) => {
    try {
      var response = await fetch("/api/logins", {
        method: "POST",
        headers: { "Content-Type": "application/json", "X-CSRF-TOKEN": token },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        // Handle non-successful HTTP responses
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      setProccessing(false);
      var return_response = {
        type: "LOGIN_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during login:", error);
      setProccessing(false);
      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function UserAdd(formData, setProccessing) {
  
  setProccessing(true);
  return async (dispatch) => {
    try {
      var response = await fetch("/api/user_add", {
        method: "POST",
        headers: {
          "X-CSRF-TOKEN": token,
          Authorization: `Bearer ${api_token} `,
        },
        body: formData,
      });

      if (!response.ok) {
        // Handle non-successful HTTP responses
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      setProccessing(false);
     
      var return_response = {
        type: "USER_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      setProccessing(false);
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);
      setProccessing(false);
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      // You might want to dispatch an action to handle the error in your application state
    }
  };
}
export function Createmenu(formData, setProccessing) {
  
  setProccessing(true);
  return async (dispatch) => {
    try {
      var response = await fetch("/api/add_menutype", {
        method: "POST",
        headers: {
          "X-CSRF-TOKEN": token,
          Authorization: `Bearer ${api_token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        // Handle non-successful HTTP responses
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      setProccessing(false);
     
      var return_response = {
        type: "CREATE_MENU",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);
      setProccessing(false);
      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function Createstation(formData, setProccessing) {
  
  setProccessing(true);
  return async (dispatch) => {
    try {
      var response = await fetch("/api/add_station", {
        method: "POST",
        headers: {
          "X-CSRF-TOKEN": token,
          Authorization: `Bearer ${api_token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        // Handle non-successful HTTP responses
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      setProccessing(false);
     
      var return_response = {
        type: "CREATE_STATION",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);
      setProccessing(false);
      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function AllUserNameData() {
  // setProccessing(true);
  return async (dispatch) => {
    try {
      var response = await fetch(
        "/api/all_user_name_data",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${api_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        // Handle non-successful HTTP responses
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      // setProccessing(false);
      var return_response = {
        type: "ADD_USER_NAME_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);

      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function AddUserRoleData(formData, setProccessing) {
  
  setProccessing(true);
  return async (dispatch) => {
    try {
      var response = await fetch("/api/add_user_role", {
        method: "POST",
        headers: {
          "X-CSRF-TOKEN": token,
          Authorization: `Bearer ${api_token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        // Handle non-successful HTTP responses
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      setProccessing(false);
     
      var return_response = {
        type: "ADD_USER_ROLE_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);

      // Toast notification for the error
      setProccessing(false);
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function AddSoundLight(formData, setProccessing) {
  setProccessing(true);
  return async (dispatch) => {
    try {
      var response = await fetch(
        "/api/add_sound_and_light",
        {
          method: "POST",
          headers: {
            "X-CSRF-TOKEN": token,
            Authorization: `Bearer ${api_token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        // Handle non-successful HTTP responses
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      setProccessing(false);
     
      var return_response = {
        type: "ADD_SOUND_LIGHT_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);

      // Toast notification for the error
      setProccessing(false);
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function FoodAndDrinksData(formData, setProccessing) {
  
  // setProccessing(true);
  return async (dispatch) => {
    try {
      var response = await fetch("/api/add_food_drinks", {
        method: "POST",
        headers: {
          "X-CSRF-TOKEN": token,
          Authorization: `Bearer ${api_token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        // Handle non-successful HTTP responses
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      // setProccessing(false);
     
      var return_response = {
        type: "FOOD_DRINKS_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);

      // Toast notification for the error
      setProccessing(false);
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function SuppliresData(formData, setProccessing) {
  
  // setProccessing(true);
  return async (dispatch) => {
    try {
      var response = await fetch("/api/add_suppliers", {
        method: "POST",
        headers: {
          "X-CSRF-TOKEN": token,
          Authorization: `Bearer ${api_token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        // Handle non-successful HTTP responses
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      // setProccessing(false);
     
      var return_response = {
        type: "SUPPLIRES_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);

      // Toast notification for the error
      setProccessing(false);
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function ExtraData(formData, setProccessing) {
  
  setProccessing(true);
  return async (dispatch) => {
    try {
      var response = await fetch("/api/add_extras", {
        method: "POST",
        headers: {
          "X-CSRF-TOKEN": token,
          Authorization: `Bearer ${api_token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        // Handle non-successful HTTP responses
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      setProccessing(false);
     
      var return_response = {
        type: "EXTRA_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);

      // Toast notification for the error
      setProccessing(false);
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function GetUserRole(id) {
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/get_userrole/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${api_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();

      var return_response = {
        type: "USER_ROLE_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);

      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function SendEmail(emailAdd, setProccessing) {
  setProccessing(true);
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/forget_password/send_otp`,
        {
          method: "POST",
          headers: {
            "X-CSRF-TOKEN": token,
            Authorization: `Bearer ${api_token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(emailAdd),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      
      setProccessing(false);
      var return_response = {
        type: "SEND_EMAIL_RESPONSE",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);
      setProccessing(false);
      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function SendOtp(emailAdd, setProccessing) {
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/forget_password/verify_otp`,
        {
          method: "POST",
          headers: {
            "X-CSRF-TOKEN": token,
            Authorization: `Bearer ${api_token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(emailAdd),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      
      setProccessing(false);
      var return_response = {
        type: "SEND_OTP_RESPONSE",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);
      setProccessing(false);
      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function SendNewPass(submitData, setProccessing) {
  setProccessing(true);
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/forget_password/reset_password
          `,
        {
          method: "POST",
          headers: {
            "X-CSRF-TOKEN": token,
            Authorization: `Bearer ${api_token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(submitData),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      
      setProccessing(false);
      var return_response = {
        type: "SEND_PASSWORD_RESPONSE",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);
      setProccessing(false);
      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function CalendarAddEvent(submitData, setProccessing) {
  setProccessing(true);
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/addEvent
          `,
        {
          method: "POST",
          headers: {
            "X-CSRF-TOKEN": token,
            Authorization: `Bearer ${api_token}`,
          },
          body: submitData,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      
      setProccessing(false);
      var return_response = {
        type: "CALENDAR_ADD_RESPONSE",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);
      setProccessing(false);
      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function UpdateUserRoleData(formData, id, setProccessing) {
  
  setProccessing(true);
  return async (dispatch) => {
    var response = await fetch(
      `/api/update_userrole/${id}`,
      {
        method: "POST",
        headers: {
          "X-CSRF-TOKEN": token,
          Authorization: `Bearer ${api_token} `,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    );
    var res_data = await response.json();
    setProccessing(false);
   
    var return_response = {
      type: "UPDATE_USER_ROLE_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}
export function AddMenuData(formData, setProccessing) {
  
  setProccessing(true);
  return async (dispatch) => {
    var response = await fetch("/api/add_menu", {
      method: "POST",
      headers: {
        "X-CSRF-TOKEN": token,
        Authorization: `Bearer ${api_token} `,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
    var res_data = await response.json();
    setProccessing(false);
   
    var return_response = {
      type: "ADD_MENU_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}
export function GetMenuTypeData() {
  return async (dispatch) => {
    try {
      // setProcessing(true);

      var response = await fetch(
        "/api/get_menu_type_data",
        {
          method: "GET",
          headers: { Authorization: `Bearer ${api_token}` },
        }
      );

      var res_data = await response.json();

      // setProcessing(false);

      var return_response = {
        type: "GET_MENU_TYPE_DATA",
        payload: res_data,
      };

      dispatch(return_response);
    } catch (error) {
      console.error("Error in GetMenuTypeData:", error);
      // setProcessing(false);
    }
  };
}
export function GetStationData() {
  return async (dispatch) => {
    try {
      // setProcessing(true);

      var response = await fetch("/api/get_station_data", {
        method: "GET",
        headers: { Authorization: `Bearer ${api_token}` },
      });

      var res_data = await response.json();

      // setProcessing(false);

      var return_response = {
        type: "GET_STATION_DATA",
        payload: res_data,
      };

      dispatch(return_response);
    } catch (error) {
      console.error("Error in GetStationData:", error);
      // setProcessing(false);
    }
  };
}
export function GetExtraData(id) {
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/get_extra_by_id/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${api_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();

      var return_response = {
        type: "GET_EXTRA_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);

      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function UpdateExtraData(formData, id, setProccessing) {
  
  setProccessing(true);
  return async (dispatch) => {
    var response = await fetch(
      `/api/update_extras/${id}`,
      {
        method: "POST",
        headers: {
          "X-CSRF-TOKEN": token,
          Authorization: `Bearer ${api_token} `,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    );
    var res_data = await response.json();
    setProccessing(false);
   
    var return_response = {
      type: "UPDATE_EXTRA_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}
export function DeleteExtraData(id) {
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/delete_extras/${id}`,
        {
          method: "POST",
          headers: {
            "X-CSRF-TOKEN": token,
            Authorization: `Bearer ${api_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();

      var return_response = {
        type: "DELETE_EXTRA_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);

      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function GetSuppliersData(id) {
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/get_supplier_by_id/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${api_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();

      var return_response = {
        type: "GET_SUPPLIERS_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);

      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function UpdateSuppliersData(formData, id, setProccessing) {
  
  setProccessing(true);
  return async (dispatch) => {
    var response = await fetch(
      `/api/update_supplier/${id}`,
      {
        method: "POST",
        headers: {
          "X-CSRF-TOKEN": token,
          Authorization: `Bearer ${api_token} `,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    );
    var res_data = await response.json();
    setProccessing(false);
   
    var return_response = {
      type: "UPDATE_SUPPLIERS_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}
export function DeleteSuppliersData(id) {
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/delete_supplier/${id}`,
        {
          method: "POST",
          headers: {
            "X-CSRF-TOKEN": token,
            Authorization: `Bearer ${api_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();

      var return_response = {
        type: "DELETE_SUPPLIERS_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);

      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function getCalendarEvent() {
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/upcoming_events
      `,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${api_token}` },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();

      var return_response = {
        type: "CALENDAR_ALL_EVENTS_RESPONSE",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);

      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function deleteCalendarEvent(id) {
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/deleteEvent/${id}`,
        {
          method: "DELETE",
          headers: {
            "X-CSRF-TOKEN": token,
            Authorization: `Bearer ${api_token} , `,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();

     
      var return_response = {
        type: "DELETE_EVENT_CAL",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);

      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function getViewAllMenuData(id) {
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/get_menu_by_id/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${api_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();

      var return_response = {
        type: "GET_VIEW_ALL_MENU_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);

      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function UpdateAllMenuData(formData, id, setProccessing) {
  
  setProccessing(true);
  return async (dispatch) => {
    var response = await fetch(
      `/api/update_menu_detail/${id}`,
      {
        method: "POST",
        headers: {
          "X-CSRF-TOKEN": token,
          Authorization: `Bearer ${api_token} `,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    );
    var res_data = await response.json();
    setProccessing(false);
   
    var return_response = {
      type: "UPDATE_ALL_MENU_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

export function DeleteAllMenuData(id) {
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/delete_menu_detail/${id}`,
        {
          method: "POST",
          headers: {
            "X-CSRF-TOKEN": token,
            Authorization: `Bearer ${api_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();

      var return_response = {
        type: "DELETE_ALL_MENU_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);

      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}

export function getViewAllMenuTypeData(id) {
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/get_menu_type_data_byid/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${api_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();

      var return_response = {
        type: "GET_VIEW_ALL_MENU_TYPE_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);

      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function UpdateAllMenuTypeData(formData, id, setProccessing) {
  
  setProccessing(true);
  return async (dispatch) => {
    var response = await fetch(
      `/api/update_menutype/${id}`,
      {
        method: "POST",
        headers: {
          "X-CSRF-TOKEN": token,
          Authorization: `Bearer ${api_token} `,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    );
    var res_data = await response.json();
    setProccessing(false);
   
    var return_response = {
      type: "UPDATE_ALL_MENU_TYPE_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}
export function DeleteAllMenuTypeData(id) {
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/delete_menutype/${id}`,
        {
          method: "POST",
          headers: {
            "X-CSRF-TOKEN": token,
            Authorization: `Bearer ${api_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();

      var return_response = {
        type: "DELETE_ALL_MENU_TYPE_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);

      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function getViewStationData(id) {
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/get_station_data_byid/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${api_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();

      var return_response = {
        type: "GET_VIEW_STATION_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);

      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}

export function UpdateStationData(formData, id, setProccessing) {
  
  setProccessing(true);
  return async (dispatch) => {
    var response = await fetch(
      `/api/update_station/${id}`,
      {
        method: "POST",
        headers: {
          "X-CSRF-TOKEN": token,
          Authorization: `Bearer ${api_token} `,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    );
    var res_data = await response.json();
    setProccessing(false);
   
    var return_response = {
      type: "UPDATE_STATION_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}
export function DeleteStationData(id) {
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/delete_station/${id}`,
        {
          method: "POST",
          headers: {
            "X-CSRF-TOKEN": token,
            Authorization: `Bearer ${api_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();

      var return_response = {
        type: "DELETE_STATION_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);

      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}

export function getEventDetailsOnId(id) {
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/getEventDetails/${id}
      `,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${api_token}` },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();

      var return_response = {
        type: "CALENDAR_EVENT_DETAIL_ON_ID",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);

      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function getEditEventDetailsOnId(id) {
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/getEventDetails1/${id}
      `,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${api_token}` },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();

      var return_response = {
        type: "EDIT_CALENDAR_EVENT_DETAIL_ON_ID",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);

      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}

export function UpdateCalendarEvent(id, submitData, setProccessing) {
  setProccessing(true);
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/updateEvents/${id}
      `,
        {
          method: "POST",
          headers: {
            "X-CSRF-TOKEN": token,
            Authorization: `Bearer ${api_token}`,
          },
          body: submitData,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      
      setProccessing(false);
      var return_response = {
        type: "CALENDAR_UPDATE_RESPONSE",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);
      setProccessing(false);
      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}

export function updateProfilePicture(submitData, setProccessing) {
  setProccessing(true);
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/update_profile
      `,
        {
          method: "POST",
          headers: {
            "X-CSRF-TOKEN": token,
            Authorization: `Bearer ${api_token}`,
          },
          body: submitData,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      setProccessing(false);
      var return_response = {
        type: "UPDATE_PROFILE_RESPONSE",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);
      setProccessing(false);
      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function getCalendarFilter(submitData, setProccessing) {
  setProccessing(true);
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/Event_Filter_PDF
      `,
        {
          method: "POST",
          headers: {
            "X-CSRF-TOKEN": token,
            Authorization: `Bearer ${api_token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(submitData),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      setProccessing(false);
      var return_response = {
        type: "CALENDAR_FILTER_RESPONSE",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);
      setProccessing(false);
      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function getAllFoodAndDrinksData() {
  return async (dispatch) => {
    try {
      var response = await fetch(`/api/food_drinks`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${api_token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();

      var return_response = {
        type: "GET_ALL_FOOD_AND_DRINKS_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);

      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}

export function getViewFoodAndDrinksData(id) {
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/get_fdrecord_by_id/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${api_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();

      var return_response = {
        type: "GET_VIEW_FOOD_AND_DRINKS_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);

      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function UpdateFoodAndDrinksData(formData, id, setProccessing) {
  
  setProccessing(true);
  return async (dispatch) => {
    var response = await fetch(
      `/api/update_food_drinks/${id}`,
      {
        method: "POST",
        headers: {
          "X-CSRF-TOKEN": token,
          Authorization: `Bearer ${api_token} `,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    );
    var res_data = await response.json();
    setProccessing(false);
   
    var return_response = {
      type: "UPDATE_FOOD_AND_DRINKS_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}
export function getAllSuppliersData() {
  return async (dispatch) => {
    try {
      var response = await fetch(`/api/suppliers`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${api_token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();

      var return_response = {
        type: "GET_ALL_SUPPLIERS_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);

      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function getAllSoundLightsData() {
  return async (dispatch) => {
    try {
      var response = await fetch(`/api/get_sound_light`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${api_token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();

      var return_response = {
        type: "GET_ALL_SOUND_LIGHT_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);

      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function getAllExtrasData() {
  return async (dispatch) => {
    try {
      var response = await fetch(`/api/extras`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${api_token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();

      var return_response = {
        type: "GET_ALL_EXTRAS_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);

      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function getViewSoundAndLightData(id) {
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/get_slrecord_by_id/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${api_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();

      var return_response = {
        type: "GET_VIEW_SOUND_AND_LIGHT_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);

      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function UpdateSoundAndLightData(formData, id, setProccessing) {
  
  setProccessing(true);
  return async (dispatch) => {
    var response = await fetch(
      `/api/update_slrecord/${id}`,
      {
        method: "POST",
        headers: {
          "X-CSRF-TOKEN": token,
          Authorization: `Bearer ${api_token} `,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    );
    var res_data = await response.json();
    setProccessing(false);
   
    var return_response = {
      type: "UPDATE_SOUND_AND_LIGHT_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}
export function createEventType(formData, setProccessing) {
  
  setProccessing(true);
  return async (dispatch) => {
    var response = await fetch(
      `/api/add_event_subdetails`,
      {
        method: "POST",
        headers: {
          "X-CSRF-TOKEN": token,
          Authorization: `Bearer ${api_token} `,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    );
    var res_data = await response.json();
    setProccessing(false);
   
    var return_response = {
      type: "CREATE_EVENT_TYPE_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}
export function getAllMenuData() {
  
  return async (dispatch) => {
    var response = await fetch(`/api/get_menuname_id`, {
      method: "GET",
      headers: {
        "X-CSRF-TOKEN": token,
        Authorization: `Bearer ${api_token} `,
      },
    });
    var res_data = await response.json();
   
    var return_response = {
      type: "GET_ALL_MENU_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}
export function UpdateChangePassword(formData, setProccessing) {
  
  setProccessing(true);
  return async (dispatch) => {
    var response = await fetch(`/api/change_password`, {
      method: "POST",
      headers: {
        "X-CSRF-TOKEN": token,
        Authorization: `Bearer ${api_token} `,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
    var res_data = await response.json();
    setProccessing(false);
   
    var return_response = {
      type: "UPDATE_CHANGE_PASSWORD",
      payload: res_data,
    };
    dispatch(return_response);
  };
}
export function getNotification() {
  return async (dispatch) => {
    try {
      var response = await fetch(`/api/logs`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${api_token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();

      var return_response = {
        type: "GET_NOTIFICATION_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);

      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function EditOrDeleteNotification(id, formdata) {
  return async (dispatch) => {
    try {
      // setProccess/ing(true);
      var response = await fetch(
        `/api/editNotifications/${id}`,
        {
          method: "POST",
          headers: {
            "X-CSRF-TOKEN": token,
            Authorization: `Bearer ${api_token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formdata),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      // setProccessing(false);
      var return_response = {
        type: "EDIT_OR_DELETE_NOTIFICATION_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);
      // setProccessing(false);
      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function MainKitchenPdfDownload(id) {
  return async (dispatch) => {
    try {
      // setProccess/ing(true);
      var response = await fetch(
        `/api/Kitchen_Get_Event_PDF/${id}`,
        {
          method: "GET",
          headers: {
            "X-CSRF-TOKEN": token,
            Authorization: `Bearer ${api_token}`,
            "Content-Type": "application/json",
          },
          // body: JSON.stringify(formdata),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      // setProccessing(false);
      var return_response = {
        type: "MAIN_KITCHEN_PDF_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);
      // setProccessing(false);
      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function transferDateEvent(formData, id, setProccessing) {
  
  setProccessing(true);
  return async (dispatch) => {
    var response = await fetch(`/api/transfer/${id}`, {
      method: "POST",
      headers: {
        "X-CSRF-TOKEN": token,
        Authorization: `Bearer ${api_token} `,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
    var res_data = await response.json();
    setProccessing(false);
   
    var return_response = {
      type: "TRANSFER_EVENT_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}
export function getKitchenEvent() {
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/get_tentative_event
      `,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${api_token}` },
          "X-CSRF-TOKEN": token,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();

      var return_response = {
        type: "CALENDAR_KITCHEN_EVENTS_RESPONSE",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);

      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function SendSetting(formdata, setProccessing) {
  setProccessing(true);
  return async (dispatch) => {
    try {
      // setProccess/ing(true);
      var response = await fetch(`/api/update_setting/1`, {
        method: "POST",
        headers: {
          "X-CSRF-TOKEN": token,
          Authorization: `Bearer ${api_token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formdata),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      // setProccessing(false)

      var res_data = await response.json();
      setProccessing(false);
      var return_response = {
        type: "SEND_SETTING",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);
      setProccessing(false);
      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}

export function getSetting() {
  // setProccessing(true)
  return async (dispatch) => {
    try {
      // setProccess/ing(true);
      var response = await fetch(`/api/get_setting/1`, {
        method: "GET",
        headers: {
          "X-CSRF-TOKEN": token,
          Authorization: `Bearer ${api_token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      // setProccessing(false)

      var res_data = await response.json();
      var return_response = {
        type: "GET_SETTING",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);
      // setProccessing(false);
      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}

export function GetBillingData(id) {
  return async (dispatch) => {
    try {
      // setProccess/ing(true);
      var response = await fetch(
        `/api/getEventBill/${id}`,
        {
          method: "GET",
          headers: {
            "X-CSRF-TOKEN": token,
            Authorization: `Bearer ${api_token}`,
            "Content-Type": "application/json",
          },
          // body: JSON.stringify(formdata),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      // setProccessing(false);
      var return_response = {
        type: "GET_BILLING_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);
      // setProccessing(false);
      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}

export function AddBillData(formData, setProccessing) {
  
  setProccessing(true);
  return async (dispatch) => {
    try {
      var response = await fetch(`/api/add_bill`, {
        method: "POST",
        headers: {
          "X-CSRF-TOKEN": token,
          Authorization: `Bearer ${api_token} `,
          // "Content-Type": "application/json",
        },
        body: formData,
      });
      var res_data = await response.json();
      setProccessing(false);
     
      var return_response = {
        type: "ADD_BILL_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);
      setProccessing(false);
      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}

export function sendRoleStatus(id, formData) {
  return async (dispatch) => {
    try {
      // setProccess/ing(true);
      var response = await fetch(
        `/api/update_userrole_status/${id}`,
        {
          method: "POST",
          headers: {
            "X-CSRF-TOKEN": token,
            Authorization: `Bearer ${api_token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      // setProccessing(false);
      var return_response = {
        type: "SEND_ROLE_STATUS_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);
      // setProccessing(false);
      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}

export function sendShowDetails(id, formData, setProccessing) {
  setProccessing(true);

  return async (dispatch) => {
    try {
      // setProccess/ing(true);
      var response = await fetch(`/api/event_mode/${id}`, {
        method: "POST",
        headers: {
          "X-CSRF-TOKEN": token,
          Authorization: `Bearer ${api_token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      setProccessing(false);
      var return_response = {
        type: "SEND_SHOW_DETAILS_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);
      setProccessing(false);
      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}

export function GetEditBillingData(id) {
  return async (dispatch) => {
    try {
      // setProccess/ing(true);
      var response = await fetch(
        `/api/getBillDetails/${id}`,
        {
          method: "GET",
          headers: {
            "X-CSRF-TOKEN": token,
            Authorization: `Bearer ${api_token}`,
            "Content-Type": "application/json",
          },
          // body: JSON.stringify(formdata),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      // setProccessing(false);
      var return_response = {
        type: "GET_EDIT_BILLING_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);
      // setProccessing(false);
      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}

export function EditBillData(formData, id, setProccessing) {
  
  setProccessing(true);
  return async (dispatch) => {
    try {
      var response = await fetch(`/api/edit_bill/${id}`, {
        method: "POST",
        headers: {
          "X-CSRF-TOKEN": token,
          Authorization: `Bearer ${api_token} `,
          // "Content-Type": "application/json",
        },
        body: formData,
      });
      var res_data = await response.json();
      setProccessing(false);
     
      var return_response = {
        type: "EDIT_BILL_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);
      setProccessing(false);
      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}

export function AdminReportsPdfDownload(id) {
  return async (dispatch) => {
    try {
      // setProccess/ing(true);
      var response = await fetch(
        `/api/Event_Get_SuperAdmin_PDF/${id}`,
        {
          method: "GET",
          headers: {
            "X-CSRF-TOKEN": token,
            Authorization: `Bearer ${api_token}`,
            "Content-Type": "application/json",
          },
          // body: JSON.stringify(formdata),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      // setProccessing(false);
      var return_response = {
        type: "ADMIN_REPORTS_PDF_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);
      // setProccessing(false);
      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function AccountReportsPdfDownload(id) {
  return async (dispatch) => {
    try {
      // setProccess/ing(true);
      var response = await fetch(
        `/api/Event_Get_Account_PDF/${id}`,
        {
          method: "GET",
          headers: {
            "X-CSRF-TOKEN": token,
            Authorization: `Bearer ${api_token}`,
            "Content-Type": "application/json",
          },
          // body: JSON.stringify(formdata),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      // setProccessing(false);
      var return_response = {
        type: "ACCOUNT_REPORTS_PDF_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);
      // setProccessing(false);
      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}

export function ReadNotification(id, formdata) {
  return async (dispatch) => {
    try {
      // setProccess/ing(true);
      var response = await fetch(
        `/api/editNotificationsRead/${id}`,
        {
          method: "POST",
          headers: {
            "X-CSRF-TOKEN": token,
            Authorization: `Bearer ${api_token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formdata),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      // setProccessing(false);
      var return_response = {
        type: "READ_NOTIFICATION_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);
      // setProccessing(false);
      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}

export function getAccountCalendarDetails() {
  return async (dispatch) => {
    try {
      // setProccess/ing(true);
      var response = await fetch(
        `/api/getAccountEventDetails`,
        {
          method: "GET",
          headers: {
            "X-CSRF-TOKEN": token,
            Authorization: `Bearer ${api_token}`,
            "Content-Type": "application/json",
          },
          // body: JSON.stringify(formdata),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      // setProccessing(false);
      var return_response = {
        type: "CALENDAR_ACCOUNTANT_DETAILS",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);
      // setProccessing(false);
      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}

export function GetUserByIdData(id) {
  return async (dispatch) => {
    try {
      var response = await fetch(`/api/get_user/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${api_token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();

      var return_response = {
        type: "GET_USER_BY_ID_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);

      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}

export function IncomeReportsChartData(formData, setProccessing) {
  setProccessing(true);
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/getBillGraph?interval=${formData}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${api_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        // Handle non-successful HTTP responses
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      setProccessing(false);
      var return_response = {
        type: "INCOME_REPORTS_CHART_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);
      setProccessing(false);
      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}

export function discountReportsChartData(formData, setProccessing) {
  setProccessing(true);
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/getDiscountGraph?interval=${formData}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${api_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        // Handle non-successful HTTP responses
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      setProccessing(false);
      var return_response = {
        type: "DISCOUNT_REPORTS_CHART_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);
      setProccessing(false);
      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}

export function SalesreportingPdfDownload(id) {
  return async (dispatch) => {
    try {
      // setProccess/ing(true);
      var response = await fetch(
        `/api/Event_Get_Sales_PDF/${id}`,
        {
          method: "GET",
          headers: {
            "X-CSRF-TOKEN": token,
            Authorization: `Bearer ${api_token}`,
            "Content-Type": "application/json",
          },
          // body: JSON.stringify(formdata),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      // setProccessing(false);
      var return_response = {
        type: "SALES_REPORTING_PDF_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);
      // setProccessing(false);
      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}

export function contract1PdfDownload(id) {

  return async (dispatch) => {
    try {
      // setProccess/ing(true);
      var response = await fetch(
        `/api/getEventContract/${id}`,
        {
          method: "GET",
          headers: {
            // "X-CSRF-TOKEN": token,
            Authorization: `Bearer ${api_token}`,
            // "Content-Type": "application/json",
          },
          // body: JSON.stringify(formdata),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      
      // setProccessing(false);
      var return_response = {
        type: "CONTRACT_1_PDF_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);
      // setProccessing(false);
      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function subContractPdfDownload(id) {

  return async (dispatch) => {
    try {
      // setProccess/ing(true);
      var response = await fetch(
        `/api/getSubEventContract/${id}`,
        {
          method: "GET",
          headers: {
            // "X-CSRF-TOKEN": token,
            Authorization: `Bearer ${api_token}`,
            // "Content-Type": "application/json",
          },
          // body: JSON.stringify(formdata),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      
      // setProccessing(false);
      var return_response = {
        type: "SUB_CONTRACT_1_PDF_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);
      // setProccessing(false);
      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}

export function createEventCalander() {
  return async (dispatch) => {
    try {
      // setProccess/ing(true);
      var response = await fetch(
        `/api/WeddingupcomingEvents`,
        {
          method: "GET",
          headers: {
            // "X-CSRF-TOKEN": token,
            Authorization: `Bearer ${api_token}`,
            // "Content-Type": "application/json",
          },
          // body: JSON.stringify(formdata),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      
      // setProccessing(false);
      var return_response = {
        type: "CREATE_EVENT_CALENDAR",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);
      // setProccessing(false);
      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function getEventsSummaryPDF(id, setProccessing) {
  setProccessing(true);
  return async (dispatch) => {
    try {
      // setProccessing(true);
      var response = await fetch(
        `/api/Event_Summary_Deatil_pdf/${id}`,
        {
          method: "GET",
          headers: {
            // "X-CSRF-TOKEN": token,
            Authorization: `Bearer ${api_token}`,
            // "Content-Type": "application/json",
          },
          // body: JSON.stringify(formdata),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
  
      setProccessing(false);
      var return_response = {
        type: "GET_SUMMARY_PDF",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);
      setProccessing(false);
      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}

export function getInvoicePDF(id, setProccessing) {
  setProccessing(true);
  return async (dispatch) => {
    try {
      // setProccessing(true);
      var response = await fetch(
        `/api/getEventsInvoicePDF/${id}`,
        {
          method: "GET",
          headers: {
            // "X-CSRF-TOKEN": token,
            Authorization: `Bearer ${api_token}`,
            // "Content-Type": "application/json",
          },
          // body: JSON.stringify(formdata),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      
      setProccessing(false);
      var return_response = {
        type: "GET_INVOICE_PDF",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);
      setProccessing(false);
      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}

export function getKitchenEventsSummaryPDF(id, setProccessing) {
  return async (dispatch) => {
    try {
      setProccessing(true);
      var response = await fetch(
        `/api/Kitchen_Event_Summary_Deatil_pdf/${id}`,
        {
          method: "GET",
          headers: {
            "X-CSRF-TOKEN": token,
            Authorization: `Bearer ${api_token}`,
            "Content-Type": "application/json",
          },
          // body: JSON.stringify(formdata),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      setProccessing(false);
      var return_response = {
        type: "GET_KITCHEN_EVENT_SUMMARY_PDF_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);
      setProccessing(false);
      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}

export function DeleteSoundAndLightData(id) {
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/delete_slrecord/${id}`,
        {
          method: "POST",
          headers: {
            "X-CSRF-TOKEN": token,
            Authorization: `Bearer ${api_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();

      var return_response = {
        type: "DELETE_SOUND_AND_LIGHT_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);

      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function AddSubInvoiceData(formData, setLoading) {
  setLoading(true);
  return async (dispatch) => {
    try {
      var response = await fetch(`/api/add_invoice`, {
        method: "POST",
        headers: {
          "X-CSRF-TOKEN": token,
          Authorization: `Bearer ${api_token}`,
          // "Content-Type": "application/json",
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      setLoading(false);
      var return_response = {
        type: "ADD_SUB_INVOICE_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);
      setLoading(false);
      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function SingleAccountPayPdf(id) {
  // setLoading(true);
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/sin_acc_pay_pdf?instalment_id=${id}`,
        {
          method: "GET",
          headers: {
            "X-CSRF-TOKEN": token,
            Authorization: `Bearer ${api_token}`,
            // "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      // setLoading(false);
      var return_response = {
        type: "SINGLE_ACCOUNT_PAY_PDF_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);
      // setLoading(false);
      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}
export function FinalAccountPayPdf(id) {
  // setLoading(true);
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/acc_pay_pdf?event_id=${id}`,
        {
          method: "GET",
          headers: {
            "X-CSRF-TOKEN": token,
            Authorization: `Bearer ${api_token}`,
            // "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      // setLoading(false);
      var return_response = {
        type: "FINAL_ACCOUNT_PAY_PDF_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);
      // setLoading(false);
      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}


export function DeleteUsersData(id) {
  return async (dispatch) => {
    try {
      var response = await fetch(
        `/api/delete_user/${id}`,
        {
          method: "POST",
          headers: {
            "X-CSRF-TOKEN": token,
            Authorization: `Bearer ${api_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();

      var return_response = {
        type: "DELETE_USER_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);

      // Toast notification for the error
      const notify = () =>
        toast.error(`An error occurred: Internal Server Error.`, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  };
}


export function UpdateUserEmailAndRole(formData, id, setProccessing) {
  setProccessing(true);
  return async (dispatch) => {
    try {
      const response = await fetch(
        `/api/update_user/${id}`, // Updated URL
        {
          method: "POST",
          headers: {
            "X-CSRF-TOKEN": token,
            Authorization: `Bearer ${api_token} `,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const res_data = await response.json();
      setProccessing(false);

      dispatch({
        type: "UPDATE_USER_EMAIL_DATA",
        payload: res_data,
      });
    } catch (error) {
      setProccessing(false);
      console.error("Error updating user:", error);
    }
  };
}

export function SaveBillEvent(id,formData, setProccessing) {
  setProccessing(true);
  return async (dispatch) => {
    try {
      const response = await fetch(
        `/api/saveEvent/${id}`, // Updated URL
        {
          method: "POST",
          headers: {
            "X-CSRF-TOKEN": token,
            Authorization: `Bearer ${api_token} `,
          },
          body: formData,
        }
      );

      const res_data = await response.json();
      setProccessing(false);

      dispatch({
        type: "SAVE_BILL_EVENT",
        payload: res_data,
      });
    } catch (error) {
      setProccessing(false);
      console.error("Error updating user:", error);
    }
  };
}