import { combineReducers } from "redux";
import {
  CrcToken_reducer,
  Loggin_reducer,
  Add_user_reducer,
  Add_user_role_data_reducer,
  Create_menu_reducer,
  Create_station_reducer,
  Add_user_name_data_reducer,
  Add_sound_light_reducer,
  Food_and_Drinks_data_reducer,
  Supplires_data_reducer,
  Extra_data_reducer,
  Get_user_role_reducer,
  Send_email_reducer,
  Send_otp_reducer,
  Send_password_reducer,
  Calendar_Add_Event_reducer,
  Update_user_role_data_reducer,
  Getuser_role_data_reducer,
  AddMenu_data_reducer,
  Get_menu_type_data_reducer,
  Get_station_data_reducer,
  Get_extra_data_reducer,
  Update_extra_data_reducer,
  Delete_extra_data_reducer,
  Get_suppliers_data_reducer,
  Update_suppliers_data_reducer,
  Delete_suppliers_data_reducer,
  Get_view_all_menu_reducer,
  Update_all_menu_reducer,
  Delete_all_menu_reducer,
  Get_view_all_menu_type_reducer,
  Update_all_menu_type_reducer,
  Get_view_station_reducer,
  Update_station_reducer,
  Delete_station_reducer,
  Delete_all_menu_type_reducer,
  Event_Details__on_id_reducer,
  Calendar_Update_Event_reducer,
  Update_Profile_reducer,
  Calendar_Filter_reducer,
  Calendar_get_Event_reducer,
  delete_Calendar_Event_reducer,
  Get_all_food_and_drinks_reducer,
  Get_view_food_and_drinks_reducer,
  Update_food_and_drinks_reducer,
  Get_all_sound_and_light_reducer,
  Get_all_suppliers_reducer,
  Get_all_extras_reducer,
  Get_view_sound_and_light_reducer,
  Update_sound_and_light_reducer,
  create_event_type,
  get_all_menu_data,
  Update_Chnage_Password_reducer,
  Edit_Calendar_Event_Details_reducer,
  Get_Notification_data_reducer,
  Edit_or_delete_Notification_data_reducer,
  Main_Kitchen_pdf_data_reducer,
  transfer_event_date_reducer,
  get_kitchen_event_calendar_reducer,
  send_setting_reducer,
  get_setting_reducer,
  get_Billing_reducer,
  Add_Billing_reducer,
  Send_Role_Status_reducer,
  Send_Show_Details_reducer,
  Edit_Billing_reducer,
  Get_Edit_Billing_reducer,
  Admin_reports_PDF_Data_reducer,
  Account_reports_PDF_Data_reducer,
  Read_Notification_Data_reducer,
  get_Calendar_Account_Details_reducer,
  get_User_by_ID_Data_reducer,
  get_Income_reports_chart_Data_reducer,
  Sales_reporting_PDF_Data_reducer,
  contract_1_PDF_Data_reducer,
  sub_contract_PDF_Data_reducer,
  get_Discount_reports_chart_Data_reducer,
  create_event_calendar_reducer,
  get_event_summary_reducer,
  get_invoice_reducer,
  get_kitchen_event_summary_PDF_reducer,
  Delete_sound_and_light_data_reducer,
  Add_sub_invoice_data_reducer,
  Single_account_pay_PDF_data_reducer,
  Final_account_pay_PDF_data_reducer,
  Delete_user_data_reducer,
  Update_user_role_email_reducer,
  Save_bill_event_reducer,
} from "./Reducer/useReducer";
const rootReducer = combineReducers({
  CrcToken_reducer,
  Loggin_reducer,
  Add_user_reducer,
  Add_user_role_data_reducer,
  Create_menu_reducer,
  Create_station_reducer,
  Add_user_name_data_reducer,
  Add_sound_light_reducer,
  Food_and_Drinks_data_reducer,
  Supplires_data_reducer,
  Extra_data_reducer,
  Get_user_role_reducer,
  Send_email_reducer,
  Send_otp_reducer,
  Send_password_reducer,
  Calendar_Add_Event_reducer,
  Update_user_role_data_reducer,
  Getuser_role_data_reducer,
  AddMenu_data_reducer,
  Get_menu_type_data_reducer,
  Get_station_data_reducer,
  Get_extra_data_reducer,
  Update_extra_data_reducer,
  Delete_extra_data_reducer,
  Get_suppliers_data_reducer,
  Update_suppliers_data_reducer,
  Delete_suppliers_data_reducer,
  Get_view_all_menu_reducer,
  Update_all_menu_reducer,
  Delete_all_menu_reducer,
  Get_view_all_menu_type_reducer,
  Update_all_menu_type_reducer,
  Get_view_station_reducer,
  Update_station_reducer,
  Delete_station_reducer,
  Delete_all_menu_type_reducer,
  Event_Details__on_id_reducer,
  Calendar_Update_Event_reducer,
  Update_Profile_reducer,
  Calendar_Filter_reducer,
  Calendar_get_Event_reducer,
  delete_Calendar_Event_reducer,
  Get_all_food_and_drinks_reducer,
  Get_view_food_and_drinks_reducer,
  Update_food_and_drinks_reducer,
  Get_all_sound_and_light_reducer,
  Get_all_suppliers_reducer,
  Get_all_extras_reducer,
  Get_view_sound_and_light_reducer,
  Update_sound_and_light_reducer,
  create_event_type,
  get_all_menu_data,
  Update_Chnage_Password_reducer,
  Edit_Calendar_Event_Details_reducer,
  Get_Notification_data_reducer,
  Edit_or_delete_Notification_data_reducer,
  Main_Kitchen_pdf_data_reducer,
  transfer_event_date_reducer,
  get_kitchen_event_calendar_reducer,
  send_setting_reducer,
  get_setting_reducer,
  get_Billing_reducer,
  Add_Billing_reducer,
  Send_Role_Status_reducer,
  Send_Show_Details_reducer,
  Edit_Billing_reducer,
  Get_Edit_Billing_reducer,
  Admin_reports_PDF_Data_reducer,
  Account_reports_PDF_Data_reducer,
  Read_Notification_Data_reducer,
  get_Calendar_Account_Details_reducer,
  get_User_by_ID_Data_reducer,
  get_Income_reports_chart_Data_reducer,
  Sales_reporting_PDF_Data_reducer,
  contract_1_PDF_Data_reducer,
  sub_contract_PDF_Data_reducer,
  get_Discount_reports_chart_Data_reducer,
  create_event_calendar_reducer,
  get_event_summary_reducer,
  get_invoice_reducer,
  get_kitchen_event_summary_PDF_reducer,
  Delete_sound_and_light_data_reducer,
  Add_sub_invoice_data_reducer,
  Single_account_pay_PDF_data_reducer,
  Final_account_pay_PDF_data_reducer,
  Delete_user_data_reducer,
  Update_user_role_email_reducer,
  Save_bill_event_reducer,
});
export default rootReducer;
